@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: "Fira Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.guest-pages-bg {
  background-image: url(/static/media/welcome-bg.8fcff48c.jpg);

  height: calc(100vh - 64px);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
}

.multipleDropdownClassName .ant-picker-dropdown {
  top: 0 !important;
}

.multipleDropdownClassName .ant-picker-cell-selected .ant-picker-cell-inner {
  color: rgba(0, 0, 0, 0.85);

  background: transparent;
}

.multipleDropdownClassName
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  content: none;
}

.ant-page-header-heading-sub-title {
  margin-top: 5px;
}

.break-word {
  word-wrap: break-word;
}

.craftProfileCard {
  transition: 0.2s;
}

.craftProfileCard:hover {
  box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
}

.profile-card .ant-card-body {
  padding-top: 0;
}

